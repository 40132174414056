<template>
  <component :is="'GModal'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-modal">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GModal } from '@twentyfourg/grimoire';

export default {
  name: 'PassportModal',
  inheritAttrs: false,
  extends: GModal,
  components: {
    GModal,
  },
};
</script>

<style lang="scss" scoped>
.passport-modal {
  ::v-deep .g-modal {
    border: 2px solid red;
  }
}
</style>
