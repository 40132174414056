<template>
  <div class="table">
    <div v-if="searchable && !loading" class="search">
      <!-- <slot name="search" v-bind="{ setSearch }">
        <SearchIcon class="search-icon" />
        <PassportInput v-model="search" v-bind:placeholder="'Search ' + heading + '...'" />
      </slot> -->
      <div class="actions">
        <PassportButton
          v-if="heading === 'groups & targets'"
          variant="danger"
          v-bind:label="'Delete Selected '"
          :disabled="selectedRows.length <= 0"
          @click="isDeleteModalOpen = true"
        />
        <PassportButton
          v-if="heading === 'trivia & quizzes'"
          variant="danger"
          v-bind:label="'Delete Selected Trivia or quiz(s)'"
          :disabled="selectedRows.length <= 0"
          @click="isDeleteModalOpen = true"
        />
      </div>
    </div>

    <div class="table-container">
      <div v-if="loading">
        <div class="skeleton-header">
          <Skeleton style="width: 100%; height: 40px" />
        </div>
        <div v-for="loading in 10" :key="loading.id" class="skeleton-row">
          <Skeleton style="width: 100%; height: 30px; margin: 10px 0" />
        </div>
      </div>
      <PassportTable
        v-if="!loading"
        style="width: 100%"
        ref="table"
        :empty-text="emptyText || 'No Data Found'"
        :data="filteredData"
        @row-click="handleRowClick"
        @expand-change="handleExpand"
      >
        <PassportTableColumn v-if="!!selectedRows" width="50px">
          <template v-slot:header>
            <PassportCheckbox v-model="allChecked" />
          </template>
          <template v-slot="props">
            <PassportCheckbox @click.stop="" v-model="props.row.checked" :id="props.row.id" />
          </template>
        </PassportTableColumn>
        <PassportTableColumn
          v-for="[key, header] in Object.entries(headers)"
          :min-width="header.minWidth || undefined"
          :key="key"
          :prop="key"
          :label="header.label || undefined"
          :sortable="header.sortable || false"
          v-bind="{ ...header }"
        >
          <template v-slot:header
            ><span class="inner-label">{{ header.label }}</span></template
          >
          <template v-if="$scopedSlots[key]" v-slot="props">
            <slot :name="key" v-bind="props" />
          </template>
        </PassportTableColumn>
        <!-- <slot name="assetType" /> -->
        <slot name="type" />
        <slot name="qrCodes" />
        <slot name="status" />
        <slot name="columnsAfter" />
      </PassportTable>
      <PassportPagination
        v-if="perPage && localTableData.length !== 0 && searchedData.length > perPage"
        v-model="currentPage"
        :totalItems="searchedData.length"
        :perPage="perPage"
      />
    </div>
    <PassportModal disableClosingOnMask v-model="deleteErrorModal">
      <div class="section">
        <div class="icon-container">
          <DeleteIcon class="icon" />
        </div>
        <span class="modal-heading">Deletion Error</span>
        <hr class="modal-separator" />
        <div class="choice-text">
          <span>
            You cannot delete a group that already has targets assigned. Please assign the existing targets to a new
            group before deleting this group.
          </span>
        </div>
        <div class="button-container">
          <PassportButton label="okay" class="okay-button" @click="deleteErrorModal = false" />
        </div>
      </div>
    </PassportModal>
    <PassportModal disableClosingOnMask v-model="isDeleteModalOpen">
      <div class="section">
        <!-- <div class="icon-container">
          <DeleteIcon class="icon" />
        </div> -->
        <span class="modal-heading">Confirm Deletion</span>
        <hr class="modal-separator" />
        <div class="choice-text">
          <span> Are you sure you want to delete this? This action cannot be undone. </span>
        </div>
        <div class="button-container">
          <PassportButton
            v-if="heading === 'groups & targets'"
            label="Yes I want to delete this"
            class="okay-button"
            @click="deleteGroups(selectedRows)"
          />
          <PassportButton
            v-else
            label="Yes I want to delete this"
            class="okay-button"
            @click="deleteMultipleQuiz(selectedRows)"
          />
          <PassportButton variant="text" label="Cancel" class="cancel-button" @click="isDeleteModalOpen = false" />
        </div>
      </div>
    </PassportModal>
  </div>
</template>

<script>
import PassportPagination from '@/components/PassportPagination.vue';
import PassportCheckbox from '@/components/PassportCheckbox.vue';
import Skeleton from '@/components/Skeleton.vue';
import PassportModal from '@/components/PassportModal.vue';
import PassportTable from '@/components/PassportTable.vue';
import PassportButton from '@/components/PassportButton.vue';
import PassportTableColumn from '@/components/PassportTableColumn.vue';
import Pagination from '@/utils/pagination';
// import PassportInput from '@/components/PassportInput.vue';
// import SearchIcon from '@/assets/icons/search_icon.svg';
import { ApiService } from '@/services/api.service';
import DeleteIcon from '@/assets/icons/trash_icon.svg';

export default {
  name: 'DataTable',
  components: {
    DeleteIcon,
    Skeleton,
    PassportModal,
    PassportButton,
    PassportPagination,
    PassportCheckbox,
    PassportTable,
    PassportTableColumn,
    // PassportInput,
    // SearchIcon,
  },
  props: {
    headers: {
      type: Object,
      default: () => {},
    },
    heading: String,
    tableData: {
      type: Array,
      default: () => {},
    },
    perPage: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: null,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Array,
      default: null,
    },
    disableSelectableRows: {
      type: Boolean,
      default: false,
    },
    defaultSort: {
      type: Object,
      default: () => ({ prop: 'title', order: 'ascending' }),
    },
  },
  data() {
    return {
      deleteErrorModal: false,
      search: '',
      confirmDelete: false,
      isDeleteModalOpen: false,
      localDefaultSort: this.defaultSort,
      currentPage: 1,
      savedPage: 1,
      localTableData: this.formatData(this.tableData),
    };
  },
  watch: {
    search(newValue, oldValue) {
      // If search started save where the pagination was
      if (oldValue === '' && newValue !== '') {
        this.savedPage = this.currentPage;
      }
      // once done searching set pagination back
      if (newValue === '' && oldValue !== '') {
        this.currentPage = this.savedPage;
      } else {
        this.currentPage = 1;
      }
    },
    checkedRows(newValue) {
      this.$emit('update:selectedRows', newValue);
    },
    selectedRows(newValue) {
      this.localTableData.forEach((row) => {
        if (newValue.some((checkedRow) => checkedRow.id === row.id)) {
          row.checked = true;
        } else {
          row.checked = false;
        }
      });
    },
    tableData(newValue) {
      this.localTableData = this.formatData(newValue);
    },
  },
  computed: {
    tableRef() {
      return this.$refs.table;
    },
    allChecked: {
      get() {
        return this.filteredData.length > 0 && this.filteredData.every((row) => row.checked);
      },
      set(checked) {
        if (!checked) {
          this.filteredData.forEach((row) => {
            row.checked = false;
          });
        } else {
          this.filteredData.forEach((row) => {
            row.checked = true;
          });
        }
      },
    },
    filteredData() {
      let data = this.localTableData;
      if (this.localDefaultSort) {
        data = this.searchedData
          .slice()
          .sort(Pagination.sortItems(this.localDefaultSort.prop, this.localDefaultSort.order));
      }
      if (this.perPage) {
        data = data.slice(this.perPage * (this.currentPage - 1), this.perPage * this.currentPage);
      }
      return data;
    },
    checkedRows() {
      return Array.from(
        this.filteredData.filter((row) => row.checked),
        (row) => row,
      );
    },
    pageCount() {
      if (!this.perPage) return 1;
      return Math.ceil(this.searchedData.length / this.perPage);
    },
    searchedData() {
      if (this.search === '') return this.localTableData;
      // console.log(this.tableData);
      return this.localTableData.filter((data) => {
        const dataKeys = Object.keys(data);
        let hasResult = false;
        dataKeys.forEach((key) => {
          if (this.headers[key]?.searchable) {
            hasResult = hasResult || data[key]?.toString().toLowerCase().includes(this.search.toLowerCase());
          }
        });
        return hasResult;
      });
    },
  },
  methods: {
    handleRowClick(row) {
      this.$emit('row-click', row);
    },
    async deleteGroups(rows) {
      // if (!rows.length) return;
      const groupIds = rows.map((row) => row.id);
      await Promise.all(
        groupIds.map(async (groupId) => {
          await ApiService.delete(`/targetGroup/${groupId}`)
            .then(() => {
              this.$notify({
                title: 'Success',
                message: 'Groups deleted successfully',
                type: 'success',
              });
              this.isDeleteModalOpen = false;
              window.location.reload();
            })
            .catch((error) => {
              if (error.response.data.error === 'group not empty') {
                this.deleteErrorModal = true;
              }
            });
        }),
      );
      // rows.forEach(({ id }) => {
      //   const index = this.users.findIndex((row) => row.id === id);
      //   if (index > -1) this.users.splice(index, 1);
      // });
    },
    async deleteMultipleQuiz(rows) {
      const quiz = {};
      Object.keys(rows).forEach((key) => {
        quiz[key] = {
          id: rows[key].id,
          quizType: rows[key].quizType,
        };
      });
      await Promise.all(
        Object.keys(quiz).map(async (key) => {
          if (quiz[key].quizType === 'trivia') {
            await ApiService.delete(`/quiz/trivia/${quiz[key].id}`);
          } else {
            await ApiService.delete(`/quiz/personality/${quiz[key].id}`);
          }
          window.location.reload();
        }),
      )
        .then(() => {
          this.$notify({
            title: 'Success',
            message: 'Quiz deleted successfully',
            type: 'success',
          });
          this.isDeleteModalOpen = false;
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            message: error.response.data.error,
            type: 'error',
          });
        });
    },
    formatData(data) {
      // This creates a new array from the old one
      // Creates new objects for each array item and adds a checked key to it
      // Since it's a new object vue will now track its items
      return data.map((row) => ({ ...row, checked: false }));
    },
    expandRow(row) {
      if (!this.$refs.table?.tableRef) return;
      this.$refs.table?.tableRef.toggleRowExpansion(row);
    },
    handleExpand(row, expandedRows) {
      if (expandedRows.find((r) => r.id === row.id)) {
        row.checked = true;
      } else {
        row.checked = false;
      }
    },
    setSearch(value) {
      this.search = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  .passport-pagination {
    margin: 0 auto;
    margin-top: 40px;
  }
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .search-icon {
    position: absolute;
    z-index: 1;
    width: 20px;
    margin-left: 1em;
  }
  ::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
    padding-left: 3em;
  }
  ::v-deep .el-table th.el-table__cell > .cell {
    font-weight: bold;
    letter-spacing: -0.18px;
  }
  ::v-deep .el-table__body-wrapper tbody tr.el-table__row div {
    cursor: pointer;
  }
  ::v-deep .el-table__header th div.cell .inner-label {
    cursor: pointer;
  }
  ::v-deep .el-table__header th.el-table__cell.is-sortable {
    cursor: initial !important;
  }
  ::v-deep .el-table__empty-block {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .actions {
    align-self: flex-end;
  }
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    padding: 90px;
    // background-image: url(../assets/images/bg_modal.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #f8ecd9;
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      margin-bottom: 40px;
      background: $light-opacity;
      border-radius: 50%;
      svg {
        width: 40px;
        height: auto;
        fill: #fff;
      }
    }
    .modal-heading {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
      font-size: 34px;
    }
    .modal-separator {
      width: 200px;
      margin-bottom: 2em;
    }
    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .okay-button {
      margin-top: 1.5em;
    }
    .cancel-button {
      margin-top: 1em;
    }
    .choice-text {
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
